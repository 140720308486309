<template>
  <div class="comContent">
    <div class="leftCover">
      <img :src="itemInfo.cover" alt="" class="cover">
      <span class="tag" v-if="itemInfo.place">{{ itemInfo.place }}</span>
    </div>

    <div class="detailRight">
      <div class="title" @click="gotoBlogDetail">{{itemInfo.title || itemInfo.name}}</div>
      <div class="content">
        {{itemInfo.content}}
      </div>
      <div class="bottomInfo flexRowVertCenter">
        <img :src="icTime" alt="" class="icTime">
        <span>{{itemInfo.createTime.split(' ')[0]}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import icTime from '../../../assets/ic_time.png'

export default {
  name: "BlogListItem",
  props: {
    itemInfo: Object
  },
  data () {
    return {
      icTime
    }
  },
  methods: {
    gotoBlogDetail() {
      let blogType = ''
      if ('title' in this.itemInfo) {
        blogType = 'article'
      } else if ('name' in this.itemInfo) {
        blogType = 'poem'
      }

      this.$router.push({
        name: 'BlogContent',
        query: {
          id: this.itemInfo.id,
          blogType
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.comContent{
  background-color: white;
  width: 800px;
  height: 150px;
  border-radius: 4px;
  box-shadow: #dfe9e8 0px 1px 4px;
  display: flex;
  box-sizing: border-box;
  padding: 20px;

  .leftCover{
    width: 150px;
    height: 120px;
    position: relative;
    overflow: hidden;
    border-radius: 4px;

    .cover{
      width: 150px;
      height: 120px;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.6s;

      &:hover{
        transform: scale(1.1);
      }
    }

    .tag{
      position: absolute;
      color: white;
      font-size: $font-size-small;
      background-color: rgba(0,0,0, 0.3);
      border-radius: 2px;
      padding: 5px 10px;
      left: 10px;
      top: 10px;
    }
  }

  .detailRight{
    height: 120px;
    margin-left: 15px;
    display: flex;
    flex-direction: column;
    flex: 1;

    .title{
      font-size: $font-size-x-large;
      font-weight: bold;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
      cursor: pointer;
    }

    .content{
      margin-top: 20px;
      font-size: $font-size-base;
      color: $paragraphGray;
      line-height: 1.7;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .bottomInfo{
      margin-top: auto;
      .icTime{
        width: 20px;
        height: 20px;
      }

      span{
        margin-left: 10px;
        font-size: $font-size-base;
        color: $paragraphGray;
      }
    }
  }
}
</style>