<template>
  <div class="comContent flexColumnHorzCenter">
    <div class="splitLine"></div>
<!--    备案信息-->
    <div class="beian" @click="onClickBeiAn">
      鄂ICP备18006174号-2
    </div>

<!--    子站点链接-->
    <div class="siteTitle flexRowVertCenter">
      <span class="line"></span>
      <span>推荐链接</span>
      <span class="line"></span>
    </div>
    <div class="siteList flexColumnHorzCenter">
      <div class="siteItem" v-for="(item, index) in siteList" :key="index" @click="onClickSubSite(item)">
        {{item.title}}
      </div>
    </div>
  </div>
</template>

<script>
import {siteList} from "@/api";

export default {
  name: "pageFooter",
  data () {
    return {
      siteList: []
    }
  },

  methods: {
    doGetSiteList () {
      siteList().then(res => {
        this.siteList = res.data
      })
    },

    onClickSubSite (item) {
      window.open(item.siteUrl)
    },

    onClickBeiAn () {
      window.open('https://beian.miit.gov.cn/')
    }
  },

  mounted() {
    this.doGetSiteList()
  }
}
</script>

<style scoped lang="scss">
.comContent{
  position: relative;
  z-index: 99;

  .beian{
    font-size: $font-size-small;
    color:$textCyan;
    cursor: pointer;
    user-select: none;
  }

  .splitLine{
    width: 500px;
    height: 1px;
    background-color: $textCyan;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .siteTitle{
    margin-top: 20px;
    .line{
      width: 30px;
      height: 1px;
      background-color: $textCyan;
      visibility: hidden;
    }

    span:nth-child(2) {
      margin: 0 10px;
      font-size: $font-size-base;
      color: $textCyan;
      font-weight: bold;

    }
  }

  .siteList{
    margin-top: 20px;
    .siteItem{
      margin-bottom: 10px;
      font-size: $font-size-base;
      color: $textCyan;
      cursor: pointer;
      &:hover{
        border-bottom: 1px solid $textCyan;
      }
    }
  }

}
</style>
