<template>
<!--照片详情信息-->
  <div class="photoDetailContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="photoList">
      <div class="photoItem" v-for="(item, index) in photoList">
        <img :src="item" alt="" class="photo" @click="toPhotoPage(item)">
      </div>
    </div>

    <div class="detailInfo" :style="{left: getFixRight() + 'px'}">
      <div class="lineData">
        <span>景点名称: </span>
        <span>{{travelDetail.placeName}}</span>
      </div>
      <div class="lineData">
        <span>地址: </span>
        <span>{{travelDetail.address}}</span>
      </div>
      <div class="lineData">
        <span>出发时间: </span>
        <span>{{travelDetail.setOutTime.split(' ')[0]}}</span>
      </div>
      <div class="lineData">
        <span>停留时长: </span>
        <span>{{travelDetail.stayTime}}</span>
      </div>
      <div class="lineData" v-if="travelDetail.description">
        <span>描述: </span>
        <span>{{travelDetail.description}}</span>
      </div>
    </div>
  </div>
</template>

<script>
import {getTravelById} from "@/api";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "travelDetail",
  mixins: [meaureMixin],
  data () {
    return {
      photoList: [],
      travelId: '',
      travelDetail: {}
    }
  },
  methods: {
    toPhotoPage (url) {
      window.open(url)
    },

    doGetTravelDetailById () {
      getTravelById({
        id: this.travelId
      }).then(res => {
        this.travelDetail = res.data
        this.photoList = this.travelDetail.photos.split(',')
      })
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    }
  },

  mounted() {
    this.travelId = this.$route.params.id
    if (!this.travelId) {
      this.$message.error("缺少必要参数")
      this.$router.go(-1)
      return
    }

    this.doGetTravelDetailById()
  }
}
</script>

<style scoped lang="scss">
@keyframes imgFadeIn {
  from {
    transform: scale(0.5);
    opacity: 0.1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.photoDetailContent{
  .photoList{
    display: flex;
    flex-wrap: wrap;

    .photoItem{
      width: 220px;
      height: 220px;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      margin-right: 20px;
      margin-bottom: 20px;

      img{
        width: 220px;
        height: 220px;
        border-radius: 5px;
        cursor: pointer;
        transition: all .6s;
        animation: imgFadeIn linear 0.5s;

        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  .detailInfo{
    position: fixed;
    bottom: 20px;

    .lineData{
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &:last-child{
        margin-bottom: 0;
      }

      span:first-child{
        color: $textCyan;
        font-size: $font-size-small;
      }

      span:nth-child(2){
        color: $textCyan;
        font-size: $font-size-small;
        margin-left: 10px;
      }
    }
  }
}
</style>
