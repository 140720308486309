import moment from 'moment'

//格式化时间戳
export function format(timestamp,formater='YYYY-MM-DD'){
    return moment(timestamp).format(formater)
}

export function formatDate(timestamp){
    return moment(timestamp).format('YYYY-MM-DD')
}

export function formatTime(timestamp){
    return moment(timestamp).format('HH:mm:ss')
}

export function formatTimeToMin(timestamp){
    return moment(timestamp).format('HH:mm')
}

export function formatDateTime(timestamp){
    return moment(timestamp).format('YYYY-MM-DD HH:mm:ss')
}

//精确到分
export function formatDateTimeToMin(timestamp){
    return moment(timestamp).format('YYYY-MM-DD HH:mm')
}

//带中文字符串的格式化
export function formatDateToText(timestamp,precision='day'){
    let timeStr=moment(timestamp).format('YYYY-MM-DD-HH-mm-ss')
    let arr=timeStr.split('-')

    if(precision=='day'){
        return arr[0]+'年'+arr[1]+'月'+arr[2]+'日'
    }

    if(precision=='hour'){
        return arr[0]+'年'+arr[1]+'月'+arr[2]+'日'+arr[3]+'时'
    }

    if(precision=='min'){
        return arr[0]+'年'+arr[1]+'月'+arr[2]+'日'+arr[3]+'时'+arr[4]+'分'
    }

    if(precision=='sec'){
        return arr[0]+'年'+arr[1]+'月'+arr[2]+'日'+arr[3]+'时'+arr[4]+'分'+arr[5]+'秒'
    }
}
