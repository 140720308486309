export const app = {
    namespaced: true,
    state: {
        showSpinner: false,
        dynamicHeight: 0,
        // 当前播放的录音id
        curPlayRecordId: '',
    },

    getters: {
        get_showSpinner: state => state.showSpinner,
        get_dynamicHeight: state => state.dynamicHeight,
        get_curPlayRecordId: state => state.curPlayRecordId
    },

    mutations: {
        mut_showSpinner: (state, payload) => {
            state.showSpinner = payload
        },

        mut_dynamicHeight: (state, payload) => {
            state.dynamicHeight = payload
        },

        mut_curPlayRecordId: (state, payload) => {
            state.curPlayRecordId = payload
        }
    }
}
