<template>
<!--留言板页面-->
  <div class="messageContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="messageList" v-if="!writeViewMode">
      <div class="messageItem" v-for="(item, index) in messageList" :key="index">
        <div class="line1">{{item.content}}</div>
        <div class="line2">{{item.createTime.split(' ')[0]}}</div>
      </div>
    </div>

    <div class="writeView" v-if="writeViewMode">
      <div class="lineInput" v-for="line in Object.keys(writeLineObj)" :key="line">
        <input type="text" v-model="writeLineObj[line]" v-if="line.includes('1')" placeholder="请输入内容...">
        <input type="text" v-model="writeLineObj[line]" v-else>
      </div>
    </div>

    <div class="writeMessage" @click="onClickBtnSend" :style="{left: getFixRight() + 'px'}">
      {{writeViewMode?'确认发送' : '写留言'}}
    </div>

<!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}" v-if="!writeViewMode">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>
  </div>
</template>

<script>
import {pageSelectMessage, insertMessage} from "@/api";
import {formatDateTime} from "@/utils/DateUtil";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  mixins: [meaureMixin],
  data () {
    return {
      writeViewMode: false,
      messageList: [],
      messageInfo: {
        content: '',
        fromIp: '',
        level: 1,
        bShow: 0,
        fromLocation: '',
        createTime: '',
      },
      pageParams: {
        limit: 6,
        page: 1
      },
      total: 0,

      writeLineObj: {}
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetMessageList()
      }
    }
  },

  methods: {
    onClickBtnSend () {
      if (!this.writeViewMode) {
        this.writeViewMode = true
        return
      }

      // 发送留言
      console.log('准备发送留言, writeLineObj=', this.writeLineObj)
      let content = ''
      for (let i=1; i<=5;i++) {
        content += this.writeLineObj['line' + i]
      }
      console.log('留言内容: ', content)
      if (!content) {
        this.writeViewMode = false
        return
      }

      if (content && content.length >= 2000) {
        content = content.substring(0, 2000)
      }
      this.messageInfo.content = content
      this.doInsertMessage()
    },

    doGetMessageList () {
      pageSelectMessage(this.pageParams)
        .then(res => {
          this.messageList = res.data.records
          this.total = res.data.total
          console.log('留言数量: ', this.total)
        })
    },

    doInsertMessage () {
      this.messageInfo.createTime = formatDateTime(new Date().getTime())
      let Ip=returnCitySN['cip']
      let cityname=returnCitySN['cname']
      this.messageInfo.fromIp = Ip
      this.messageInfo.fromLocation = cityname
      // console.log('添加留言, messageInfo = ', this.messageInfo)
      insertMessage(this.messageInfo)
        .then(res => {
          this.$message.success('发送成功')
          this.messageInfo.content = ''
          this.writeViewMode = false
        })
    },

    initWriteLineObj () {
      for (let i =1; i<=5; i++) {
        this.$set(this.writeLineObj, 'line'+i, '')
      }
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    }
  },

  mounted() {
    this.doGetMessageList()
    this.initWriteLineObj()
  }
}
</script>

<style lang="scss">
.ant-pagination-item {
  border-radius: 50%;
  background-color: $textCyan;
}

.ant-pagination-item-active{
  border-color: $textCyan;
  color: white;
}
</style>

<style scoped lang="scss">
.messageContent{
  .messageList{
    .messageItem{
      margin-bottom: 20px;

      .line1{
        font-size: $font-size-middle;
        color: $textCyan;
        text-align: left;
        line-height: 1.6;

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .line2{
        font-size: $font-size-small;
        text-align: right;
        color: $textCyan;
        text-align: left;
        margin-top: 10px;
      }
    }
  }

  .writeView{
    .lineInput{
      input{
        outline: none;
        border: none;
        border-bottom: 1px solid $textCyan;
        padding: 6px 10px;
        font-size: $font-size-middle;
        color: $textCyan;
        font-family: '楷体';
        background-color: transparent;
        width: 100%;
      }
    }
  }

  .writeMessage{
    position: fixed;
    bottom: 50px;
    font-size: $font-size-middle;
    color: $textCyan;
    border-bottom: 1px solid $textCyan;
    cursor: pointer;
    &:hover{
      font-weight: bold;
    }
  }

  .pagination{
    position: fixed;
    bottom: 50px;
  }
}
</style>
