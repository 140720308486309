<template>
<!--旅途列表页面-->
  <div class="pageContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="travelList">
      <div class="travelItem" v-for="(item, index) in travelList" :key="index">
        <travel-list-item :item-info="item" />
      </div>
    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>
  </div>
</template>

<script>
import {pageSelectTravel} from "@/api";
import TravelListItem from "@/pages/travel/coms/TravelListItem";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  components: {TravelListItem},
  mixins: [meaureMixin],
  data () {
    return {
      travelList: [],
      pageParams: {
        page: 1,
        limit: 10
      },
      total: 0
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetTravelList()
      }
    }
  },

  methods: {
    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    doGetTravelList () {
      pageSelectTravel(this.pageParams)
        .then(res => {
          this.travelList = res.data.records
          this.total = res.data.total
        })
    }
  },

  mounted() {
    this.doGetTravelList()
  }
}
</script>

<style scoped lang="scss">
.pageContent{
  .travelList{
    display: flex;
    flex-wrap: wrap;
    .travelItem{
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }

  .pagination{
    position: fixed;
    bottom: 50px;
  }

}
</style>
