import {mapMutations,mapGetters} from "vuex";

export const meaureMixin = {
    computed: {
        ...mapGetters('app', ['get_dynamicHeight']),
    },

    methods: {
        ...mapMutations('app', ['mut_dynamicHeight']),

        calcDyanmicMaxHeight () {
            if (this.get_dynamicHeight) {
                return this.get_dynamicHeight
            }

            const marginHeight = 160
            const titleHeight = this.$refs.mainTitle.offsetHeight
            const categoryHeight = this.$refs.categoryList.offsetHeight
            const remainHeight = document.documentElement.clientHeight - (marginHeight + titleHeight + categoryHeight)
            console.log('titleHeight=', titleHeight)
            console.log('categoryHeight=', categoryHeight)
            console.log('remainHeight=', remainHeight)
            this.mut_dynamicHeight(remainHeight)
            return remainHeight
        },

        getFixRight () {
            return (document.documentElement.offsetWidth - 1190) / 2
        }
    }
}
