<template>
  <!--  博客详情页面-->
  <div class="blogContentPage" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <img :src="icX" alt="" class="back cursorPointer" @click="goback" :style="{right: getFixRight() + 'px'}">
    <div class="mainContents">
      <div class="contentArea">
        <mavonEditor
            :subfield="false"
            :autofocus="false"
            defaultOpen="preview"
            :toolbarsFlag="false"
            :value="blogContent.content"
            ref="md"
            :boxShadow="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import {getArticleById, getPoemById, updateArticle} from "@/api";
import icX from '../../assets/ic_X.png'
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "blogContent",
  components:{mavonEditor},
  mixins: [meaureMixin],

  data(){
    return{
      icX,
      blogContent:{},
      blogId:'',
      blogType: '',
      commentList:[],
      fixRight: 0
    }
  },

  methods:{
    goback(){
      this.$router.go(-1)
    },

    // 更新文章详情
    doUpdateArticle (article) {
      updateArticle(article).then(() => {})
    },

    //API 根据博客id查询博客详情
    doGetBlogDetailById(){
      if (this.blogType === 'article') {
        getArticleById({
          id: this.blogId
        }).then(res=>{
          this.blogContent=res.data

          // 更新文章浏览数量
          let article = {...this.blogContent}
          if(!article.viewnum) {
            article.viewnum = 1
          } else {
            article.viewnum++
          }

          this.doUpdateArticle(article)
        })
      } else if (this.blogType === 'poem') {
        getPoemById({
          id: this.blogId
        }).then(res=>{
          this.blogContent=res.data
        })
      }
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    }
  },

  mounted() {
    const routeParams = this.$route.params
    const queryParams = this.$route.query

    console.log('博客详情页，路由参数：',routeParams)
    console.log('博客详情页，查询参数', queryParams)

    //this.blogContent=routeParams.blogContent
    if (routeParams.id) {
      this.blogId = routeParams.id
      this.blogType = routeParams.blogType
    } else {
      this.blogId = queryParams.id
      this.blogType = queryParams.blogType
    }

    console.log('博客id: ', this.blogId)
    console.log('blogType=', this.blogType)
    this.doGetBlogDetailById()
  }

}
</script>

<style scoped lang="scss">
.blogContentPage{
  position: relative;

  .mainContents{
    .contentArea{
      // max-height: 80vh;
      overflow-y: auto;
      margin-bottom: 100px;

      .commentList{
        margin: 30px;
        .commentTitle{
          font-size: $font-size-middle;
          color:$textBlack;
          font-weight: bold;
          margin-bottom: 30px;
        }

        .commentItem{
          margin-bottom: 20px;
          padding-bottom: 10px;
          border-bottom: 1px solid $dividerColor;

          &:last-child{
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .back{
    width: 20px;
    height: 20px;
    position: fixed;
    z-index: 9999;
    transform: rotate(0deg);
    transition: all 0.3s;
    margin-right: -35px;

    &:hover{
      // transform: rotate(180deg);
      width: 20px;
      height: 20px;
    }
  }
}
</style>
