import axios from 'axios'
import vueInstance from '@/eventBus/bus'
import {LStorage,SStorage} from "../utils/storage";
import router from "@/router"
import qs from 'querystring'
import store from '@/store'

axios.defaults.timeout = 5000; //超时时间是5秒
axios.defaults.withCredentials = true; //允许跨域
//Content-type 响应头
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
//基础URL
// axios.defaults.baseURL ="http://127.0.0.1:8086"
// axios.defaults.baseURL ="http://59.110.104.191:8086"
// axios.defaults.baseURL ="http://101.34.48.167:8086"
console.log('NODE_ENV=', process.env.NODE_ENV)
if(process.env.NODE_ENV === 'development') {
    // axios.defaults.baseURL ="http://127.0.0.1:8086"
    axios.defaults.baseURL ="http://www.leaveback.cn:8086"
} else {
    axios.defaults.baseURL ="http://www.leaveback.cn:8086"
}

// 显示加载提示框
function showLoading () {
    console.log('store=', store)
    store.commit('app/mut_showSpinner', true)
}

// 隐藏加载提示框
function hideLoading () {
    store.commit('app/mut_showSpinner', false)
}

//请求拦截器
axios.interceptors.request.use(config => {
    /*let uid=SStorage.getItem('uid')
    let token=SStorage.getItem('token')
    if(!uid)
        uid=LStorage.getItem('uid')
    if(!token)
        token=LStorage.getItem('token')

    console.log('axiox请求拦截器，uid=',uid,' token=',token)*/

    config.headers = {
        // 'SwaggerTestHeader': 1,
        // 'Authorization':uid+';'+token,
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    }

    //显示加载对话框
    showLoading()

    return config;
}, error => {
    return Promise.reject(error);
})

//响应拦截器
axios.interceptors.response.use(
    response => {
        //隐藏加载对话框
        hideLoading()

        if (response.data.code == 200) {
            return Promise.resolve(response);
        }else if(response.data.code==401){
            vueInstance.$alert('登录失效，请重新登录', '提示', {
                confirmButtonText: '确定',
                showClose:false,
                callback: action => {
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });

                    vueInstance.$store.commit('setBShowLogExpiredDialog',0)
                }
            });
        }
        else {
            vueInstance.$toast(response.data.message)
            return Promise.reject(response);
        }
    },
    error => {
        //隐藏加载对话框
        hideLoading()

        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    vueInstance.$alert('登录失效，请重新登录', '提示', {
                        confirmButtonText: '确定',
                        showClose:false,
                        callback: action => {
                            router.replace({
                                path: '/login',
                                query: {
                                    redirect: router.currentRoute.fullPath
                                }
                            });

                            vueInstance.$store.commit('setBShowLogExpiredDialog',0)
                        }
                    });
                    break;

                case 404:
                    break;
            }

            return Promise.reject(error.response);
        }
    })

/**
 * 封装get方法
 */
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        axios.get(url, { params })
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}

/**
 * 封装post方法
 */
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(data))
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error);
            })
    });
}
