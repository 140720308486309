import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/index/Index.vue'
import BlogList from '../pages/blog/index.vue'
import PoemList from '../pages/poem/index.vue'
import blogContent from '@/pages/blog/blogContent'
import photograph from '@/pages/photograph/index'
import photoDetail from '@/pages/photograph/photoDetail'
import messageBoard from '@/pages/messageBoard/index'
import songRecord from '@/pages/songRecord/index'
import travel from '@/pages/travel/index'
import travelDetail from '@/pages/travel/travelDetail'
import videoAlbum from '@/pages/video/index'
import videoDetail from '@/pages/video/videoDetail'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/blog',
    children: [
      {
        path: '/blog',
        name: 'Blog',
        component: BlogList
      },
      {
        path: '/poem',
        name: 'Poem',
        component: PoemList
      },
      {
        path: '/blogContent/:id',
        name: 'BlogContent',
        component: blogContent
      },
      {
        path: '/photograph',
        name: 'Photograph',
        component: photograph
      },
      {
        path: '/photoDetail/:id',
        name: 'PhotoDetail',
        component: photoDetail
      },
      {
        path: '/messageBoard',
        name: 'MessageBoard',
        component: messageBoard
      },
      {
        path: '/songRecord',
        name: 'SongRecord',
        component: songRecord
      },
      {
        path: '/travel',
        name: 'Travel',
        component: travel
      },
      {
        path: '/travelDetail/:id',
        name: 'TravelDetail',
        component: travelDetail
      },
      {
        path: '/videoAlbum',
        name: 'VideoAlbum',
        component: videoAlbum
      },
      {
        path: '/videoDetail/:id',
        name: 'VideoDetail',
        component: videoDetail
      },
    ]
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  routes
})

export default router
