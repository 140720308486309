<template>
  <div>
<!--    <audio ref="refAudio"></audio>-->
  </div>
</template>

<script>
import {globalEvents} from "../../global/globalEvents";
import eventBus from '../../eventBus/bus'

const refAudio=new Audio()

export default {
  name: "AudioPlayer",

  data(){
    return{

    }
  },

  mounted() {
    this.$nextTick(()=>{
      eventBus.$on(globalEvents.EVENT_PLAY_AUDIO,()=>{
        console.log('收到播放音频事件')

        refAudio.play()
      })

      eventBus.$on(globalEvents.EVENT_PAUSE_AUDIO,()=>{
        refAudio.pause()
      })

      eventBus.$on(globalEvents.EVENT_SET_AUDIO_SOURCE,(params)=>{
        console.log('收到设置音频源事件，source=',params.source)
        refAudio.src=params.source
      })

      eventBus.$on(globalEvents.EVENT_SET_AUDIO_LOOP,()=>{
        refAudio.loop=true
      })

      eventBus.$on(globalEvents.EVENT_CANCEL_AUDIO_LOOP,()=>{
        refAudio.loop=false
      })
      console.log('audio-player,设置监听器完成')
    })

  }
}
</script>

<style scoped>

</style>
