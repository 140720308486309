<template>
  <div id="app" :style="{backgroundImage: `url(${mainBg})`}">
<!--    全局loading-->
    <div class="spinContainer flexRowAllCenter">
      <a-spin size="large" tip="" wrapperClassName="spin" v-show="get_showSpinner"
        style="z-index: 99999;"/>
    </div>
    <router-view/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import mainBg from './assets/bg.jpg'
import {insertVisit} from "./api";
import {formatDateTime} from "./utils/DateUtil";

export default {
  data () {
    return {
      mainBg,
      visitAddress: '',
    }
  },

  computed: {
    ...mapGetters('app', ['get_showSpinner'])
  },

  methods: {
    // API 添加一条访问记录
    doInsertVisitRecord () {
      if (!this.visitAddress)
        return

      let Ip=returnCitySN['cip']
      let cityname=returnCitySN['cname']

      let params = {
        ip: Ip,
        location: this.visitAddress,
        fromsite: 'pc',
        time: formatDateTime(new Date().getTime())
      }

      insertVisit(params)
          .then(() => {
          })
    },

    getLngLatLocation() {
      console.log('getLngLatLocation')
      let that = this
      AMap.plugin("AMap.CitySearch", function() {
        let citySearch = new AMap.CitySearch()
        citySearch.getLocalCity(function(status, result) {
          console.log('高德地图定位返回：', status, result)
          if (status === "complete" && result.info === "OK") {
            // 查询成功，result即为当前所在城市信息
            console.log("通过ip获取当前城市：", result)
            that.visitAddress = result.province + ' ' + result.city
            console.log('游客地址：', that.visitAddress)
            that.doInsertVisitRecord()

            //逆向地理编码
            AMap.plugin("AMap.Geocoder", function() {
              var geocoder = new AMap.Geocoder({
                // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycoded
                city: result.adcode
              })
              let lnglat = result.rectangle.split(";")[0].split(",")
              geocoder.getAddress(lnglat, function(status, data) {
                if (status === "complete" && data.info === "OK") {
                  console.log('逆向地理编码获取的信息：', data)
                }
              })
            })
          }
        })
      })
    },
  },

  mounted() {
    this.getLngLatLocation()
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.spinContainer{
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;

  .spin{
    position: fixed;
    left: 50%;
    top:50%;
    transform: translate(-50%, -50%);
  }
}

</style>
