<template>
  <div class="comContent" @click="gotoDetail">
    <span class="placeName">{{itemInfo.placeName}}</span>
    <span class="setOutTime">{{itemInfo.setOutTime.split(' ')[0]}}</span>
    <span class="count">共{{itemInfo.photos.split(',').length}}张</span>
    <img :src="itemInfo.photos.split(',')[0]" alt="" class="cover">
  </div>
</template>

<script>
export default {
  name: "TravelListItem",
  props: {
    itemInfo: Object
  },
  methods: {
    gotoDetail () {
      this.$router.push({
        name: 'TravelDetail',
        params: {
          id: this.itemInfo.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.comContent{
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 5px;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  .placeName{
    position: absolute;
    color: white;
    font-size: $font-size-small;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 2px;
    padding: 5px 10px;
    left: 5px;
    top: 5px;
  }

  .setOutTime{
    position: absolute;
    color: white;
    font-size: $font-size-small;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 2px;
    padding: 5px 10px;
    bottom: 10px;
    right: 10px;
  }

  .count{
    position: absolute;
    color: white;
    font-size: $font-size-small;
    background-color: rgba(0,0,0, 0.3);
    border-radius: 2px;
    padding: 5px 10px;
    bottom: 10px;
    left: 10px;
  }

  .square{
    width: 150px;
    height: 150px;
    border: 1px solid white;
    position: absolute;
    left: 25px;
    top: 25px;
  }

  .cover{
    width: 200px;
    height: 200px;
    border-radius: 5px;
    cursor: pointer;
    transition: all .6s;
    &:hover{
      transform: scale(1.1);
    }
  }
}
</style>
