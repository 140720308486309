<template>
  <div class="videoContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="photoList">
      <div class="photoItem" v-for="(item, index) in albumList" :key="index"
           @click="onClickAlbumItem(item)" v-if="item.videoCount > 0">
        <span class="albumTitle">{{item.title}}</span>
        <span class="albumName">{{item.videoCount}}个视频</span>
        <img :src="item.cover" alt="" class="cover">
      </div>
    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>
  </div>
</template>

<script>
import {videoAlbumList} from "@/api";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  mixins: [meaureMixin],
  data () {
    return {
      albumList: [],

      pageParams: {
        limit: 10,
        page: 1
      },

      total: 0
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetVideoAlbumList()
      }
    }
  },

  methods: {
    doGetVideoAlbumList() {
      videoAlbumList().then(res=>{
        this.albumList = res.data || []
        this.total = this.albumList.length
      })
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    onClickAlbumItem (item) {
      this.$router.push({
        name: 'VideoDetail',
        params: {
          id: item.id
        }
      })
    }
  },

  mounted() {
    this.doGetVideoAlbumList()
  }
}
</script>

<style scoped lang="scss">
.videoContent {
  .photoList {
    display: flex;
    flex-wrap: wrap;

    .photoItem {
      width: 200px;
      height: 200px;
      position: relative;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 20px;
      overflow: hidden;

      .albumName {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        left: 5px;
        top: 5px;
      }

      .albumTitle {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        right: 10px;
      }

      .count {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        left: 10px;
      }

      .square {
        width: 150px;
        height: 150px;
        border: 1px solid white;
        position: absolute;
        left: 25px;
        top: 25px;
      }

      .cover {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        cursor: pointer;
        transition: all .6s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .pagination {
    position: fixed;
    bottom: 50px;
  }
}
</style>
