<template>
  <div class="comContent flexRowVertCenter">
    <div class="leftPart">
      <img :src="itemInfo.cover" alt="" class="cover">
      <img :src="icPlay" alt="" class="icPlay" v-if="!itemInfo.playing" @click="playAudio(itemInfo)">
      <img :src="icPause" alt="" class="icPause" v-if="itemInfo.playing" @click="stopAudio(itemInfo)">
      <div class="mask"></div>
    </div>
    <div class="rightPart">
      <div class="line1">
        <span class="name">{{itemInfo.name}}</span>
        <span class="singer">{{itemInfo.singer}}</span>
      </div>
    </div>

    <div class="line2 flexRowVertCenter">
      <span class="coverSinger">cover: {{itemInfo.coverAuthor}}</span>
      <span class="createTime">{{itemInfo.createTime.split(' ')[0]}}</span>
    </div>

    <audio-player />
  </div>
</template>

<script>
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import {globalEvents} from "@/global/globalEvents";
import eventBus from '../../../eventBus/bus'
import icPlay from '../../../assets/ic_play.png'
import icPause from '../../../assets/ic_pause.png'
import {mapMutations} from 'vuex'

export default {
  name: "SongRecordItem",
  components: {AudioPlayer},
  props: {
    itemInfo: Object
  },
  data () {
    return {
      icPlay,
      icPause,

      curMediaUrl: ''
    }
  },
  methods: {
    ...mapMutations('app', ['mut_curPlayRecordId']),

    playAudio (item) {
      const { mediaUrl } = item
      if (mediaUrl !== this.curMediaUrl) {
        this.curMediaUrl = mediaUrl
        eventBus.$emit(globalEvents.EVENT_SET_AUDIO_SOURCE, {source: this.curMediaUrl})
        eventBus.$emit(globalEvents.EVENT_SET_AUDIO_LOOP, {})
        eventBus.$emit(globalEvents.EVENT_PLAY_AUDIO, {})
      } else {
        eventBus.$emit(globalEvents.EVENT_PLAY_AUDIO, {})
      }
      item.playing = true
      this.mut_curPlayRecordId(item.id)
      this.$emit('playRecord', {record: item})
    },

    stopAudio (item) {
      eventBus.$emit(globalEvents.EVENT_PAUSE_AUDIO, {})
      item.playing = false
      this.mut_curPlayRecordId('')
    }
  }
}
</script>

<style scoped lang="scss">
$unitHeight: 120px;

.comContent {
  width: 800px;
  height: $unitHeight;
  border-radius: 4px;
  box-shadow: #dfe9e8 0px 1px 4px;
  padding: 20px;
  position: relative;
  cursor: pointer;

  &:hover{
    box-shadow:  0 4px 8px 0 #dfe9e8, 0 6px 20px 0 #dfe9e8;
  }

  .leftPart {
    position: relative;
    overflow: hidden;
    .cover {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    .mask{
      position: absolute;
      left: 0;
      top: 0;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: rgba(0,0,0, 0.3);
    }

    .icPlay,.icPause{
      width: 30px;
      height: 30px;
      cursor: pointer;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 9;
      &:hover{
      }
    }

    .icPause{
      width: 20px;
      height: 20px;
    }
  }

  .rightPart {
    flex: 1;
    margin-left: 15px;
    display: flex;
    flex-direction: column;

    .line1 {
      display: flex;
      align-items: flex-end;

      .name {
        font-size: $font-size-x-large;
        font-weight: bold;
        color: $textCyan;
      }

      .singer {
        margin-left: 10px;
        font-size: $font-size-base;
        color: $textCyan;
      }
    }
  }

  .line2 {
    margin-top: auto;
    margin-bottom: 10px;
    position: absolute;
    right: 20px;
    bottom: 0;

    .coverSinger {
      font-size: $font-size-base;
      color: $textCyan;
    }

    .createTime {
      margin-left: 20px;
      font-size: $font-size-small;
      color: $textCyan;
    }
  }
}
</style>
