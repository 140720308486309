import {get,post} from './http'

// 首页轮播图
export const indexBanners=(params)=>post('/front/indexBanners',params)

// 可访问栏目列表
export const categoryList=(params)=>post('/front/categoryList',params)

// 最近的n篇文章
export const getRecentArticles=(params)=>post('/front/recentArticles',params)

// 最近的n篇诗文
export const getRecentPoems=(params)=>post('/front/recentPoems',params)

// 根据文章id获取文章详情
export const getArticleById=(params)=>post('/front/getArticleById',params)

// 根据诗文id获取诗文详情
export const getPoemById=(params)=>post('/front/getPoemById',params)

// 分页查询照片列表
export const pageSelectPhotograph=(params)=>post('/front/pageSelectPhotograph',params)

// 查询全部影集列表
export const getAlbumList=(params)=>post('/front/albumList',params)

// 根据照片id获取照片详情
export const getPhotoById=(params)=>post('/front/getPhotoById',params)

// 分页查询留言列表
export const pageSelectMessage=(params)=>post('/front/pageSelectMessage',params)

// 添加留言
export const insertMessage=(params)=>post('/front/insertMessage',params)

// 分页查询录音列表
export const pageSelectSongRecord=(params)=>post('/front/pageSelectSongRecord',params)

// 分页查询旅途列表
export const pageSelectTravel=(params)=>post('/front/pageSelectTravel',params)

// 根据旅途id获取旅途详情
export const getTravelById=(params)=>post('/front/getTravelById',params)

// 分页查询文章列表
export const pageSelectArticle=(params)=>post('/front/pageSelectArticle',params)

// 分页查询诗文列表
export const pageSelectPoem=(params)=>post('/front/pageSelectPoem',params)

// 查询全部子站点
export const siteList=(params)=>post('/front/siteList',params)

// 获取最新一条媒体推荐
export const latestReommend=(params)=>post('/front/latestReommend',params)

// 添加一条访问记录
export const insertVisit=(params)=>post('/front/insertVisit',params)

// 更新文章(浏览数量)
export const updateArticle=(params)=>post('/front/updateArticle',params)

// 查询视频专辑列表
export const videoAlbumList=(params)=>post('/front/videoAlbumList',params)

// 分页查询视频列表
export const pageSelectVideo=(params)=>post('/front/pageSelectVideo',params)

// 更改视频
export const updateVideo=(params)=>post('/front/updateVideo',params)
