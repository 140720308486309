<template>
<!--  录音管理页面-->
  <div class="recordPageContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="songRecordList">
      <div class="songRecordItem" v-for="(item, index) in songRecordList" :key="index">
        <song-record-item :item-info="item" @playRecord="onPlayRecord"/>
      </div>
    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>
  </div>
</template>

<script>
import {pageSelectSongRecord} from "@/api";
import SongRecordItem from "@/pages/songRecord/coms/SongRecordItem";
import {meaureMixin} from "@/mixins/meaureMixin";
import {mapGetters} from 'vuex'

export default {
  name: "index",
  components: {SongRecordItem},
  mixins: [meaureMixin],
  data () {
    return {
      pageParams: {
        limit: 10,
        page: 1
      },
      total: 0,
      songRecordList: []
    }
  },

  computed: {
    ...mapGetters('app', ['get_curPlayRecordId'])
  },

  methods: {
    onPlayRecord ({record}) {
      console.log('收到playRecord事件，record=', record)
      this.songRecordList.forEach(_ => _.playing = false)
      this.songRecordList.find(_ => _.id === record.id).playing = true
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    doGetSongRecordList () {
      pageSelectSongRecord(this.pageParams)
        .then(res => {
          this.songRecordList = res.data.records
          this.total = res.data.total
          this.songRecordList.forEach(_ => {
            this.$set(_, 'playing', false)
            if (this.get_curPlayRecordId === _.id) {
              _.playing = true
            }
          })
        })
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetSongRecordList()
      }
    }
  },

  mounted() {
    this.doGetSongRecordList()
  }
}
</script>

<style scoped lang="scss">
.recordPageContent{
  .songRecordList{
    .songRecordItem{
      margin-bottom: 20px;
    }
  }

  .pagination{
    position: fixed;
    bottom: 50px;
  }
}
</style>
