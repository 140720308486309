<template>
  <div class="blogContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="blogList">
      <div class="blogItem" v-for="(item, index) in poemList">
        <blog-list-item :item-info="item" />
      </div>
    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>
  </div>
</template>

<script>
import {pageSelectPoem} from "@/api";
import BlogListItem from "@/pages/blog/coms/BlogListItem";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  components: {BlogListItem},
  mixins: [meaureMixin],
  data () {
    return {
      poemList: [],
      pageParams: {
        limit: 5,
        page: 1
      },
      total: 0
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetRecentPoemList()
      }
    }
  },

  methods: {
    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    doGetRecentPoemList () {
      pageSelectPoem(this.pageParams).then(res => {
        this.poemList = res.data.records
        this.total = res.data.total
      })
    }
  },


  mounted() {
    this.doGetRecentPoemList()
  }
}
</script>

<style scoped lang="scss">
.blogContent{
  .blogList{
    .blogItem{
      margin-bottom: 20px;
    }
  }

  .pagination{
    position: fixed;
    bottom: 50px;
  }
}
</style>
