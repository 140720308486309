<template>
<!--摄影页面-->
  <div class="photoContent" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="photoList">
      <div class="photoItem" v-for="(item, index) in photoList" :key="index"
        @click="onClickPhotoItem(item)">
        <span class="albumName">{{getAlbumNameById(item.albumId)}}</span>
        <span class="albumTitle">{{item.title}}</span>
        <div class="square" v-if="false"></div>
        <span class="count">共{{item.urls.split(',').length}}张</span>
        <img :src="item.urls.split(',')[0]" alt="" class="cover">
      </div>
    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>

  </div>
</template>

<script>
import {pageSelectPhotograph, getAlbumList} from "@/api";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  mixins: [meaureMixin],
  data () {
    return {
      photoList: [],
      albumList: [],
      pageParams: {
        limit: 10,
        page: 1
      },

      total: 0
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetPhotoList()
      }
    }
  },

  methods: {
    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    onClickPhotoItem (item) {
      this.$router.push({
        name: 'PhotoDetail',
        params: {
          id: item.id
        }
      })
    },

    getAlbumNameById (id) {
      const albumItem = this.albumList.find(_ => _.id === id)
      return albumItem ? albumItem.name : ''
    },

    doGetPhotoList() {
      pageSelectPhotograph(this.pageParams)
        .then(res => {
          console.log('分页查询照片列表,返回: ', res.data)
          this.photoList = res.data.records
          this.total = res.data.total
        })
    },

    doGetAlbumList () {
      getAlbumList()
        .then(res => {
          this.albumList = res.data

          this.doGetPhotoList()
        })
    }
  },

  mounted() {
    this.doGetAlbumList()
  }
}
</script>

<style scoped lang="scss">
.photoContent{
  .photoList{
    display: flex;
    flex-wrap: wrap;

    .photoItem{
      width: 200px;
      height: 200px;
      position: relative;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 20px;
      overflow: hidden;

      .albumName{
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0,0,0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        left: 5px;
        top: 5px;
      }

      .albumTitle{
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0,0,0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        right: 10px;
      }

      .count{
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0,0,0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        left: 10px;
      }

      .square{
        width: 150px;
        height: 150px;
        border: 1px solid white;
        position: absolute;
        left: 25px;
        top: 25px;
      }

      .cover{
        width: 200px;
        height: 200px;
        border-radius: 5px;
        cursor: pointer;
        transition: all .6s;
        &:hover{
          transform: scale(1.1);
        }
      }
    }
  }

  .pagination{
    position: fixed;
    bottom: 50px;
  }
}
</style>
