<template>
<!--首页-->
  <div class="pageContent flexColumnHorzCenter">
<!--    轮播图-->
<!--    <div class="bannerArea">
      <a-carousel :autoplay="true" dotsClass="indicator" :dots="false">
        <div class="bannerItem" v-for="(item, index) in bannerList" :key="index">
          <img :src="item.imgUrl" alt="">
          <span class="bannerTitle">{{item.title}}</span>
        </div>
      </a-carousel>
    </div>-->
    <div class="mainContents">
<!--      标题-->
      <div class="mainTitle" ref="mainTitle">
        <img :src="imgLgqt" alt="">
      </div>
<!--      栏目-->
      <div class="categoryList flexRowVertCenter" ref="categoryList">
<!--        <div class="categoryItem flexColumnHorzCenter" :class="{activeCategory: curActiveCategory === '首页'}"
          @click="onClickCategory({category: '首页'})">
          <span>首页</span>
          <span class="border"></span>
        </div>-->
        <div class="categoryItem flexColumnHorzCenter" v-for="(item, index) in categoryList" :key="index"
             :class="{activeCategory: curActiveCategory === item.category}"
            @click="onClickCategory(item)">
          <span>{{item.category}}</span>
          <span class="border"></span>
        </div>
      </div>

<!--      动态展示的内容-->
      <div class="dynamicArea" :style="{maxHeight: (calcDyanmicMaxHeight()) + 'px', minHeight: calcDyanmicMaxHeight() + 'px'}" v-if="bMounted">
        <router-view />
        <!--    底部栏-->
        <page-footer />
      </div>
    </div>

<!--    显示图片媒体推荐的对话框-->
      <div class="recommendPicContainer flexRowAllCenter" v-if="bShowPicRecommend" @click="onClickPicContainer">
        <img :src="picRecommendUrl" alt="" class="recommendImg" :style="{maxWidth: clientWidth*0.8+'px', maxHeight: clientHeight*0.8+'px'}">
      </div>

    <!--    播放推荐视频的对话框-->
    <a-modal
        v-model="bShowVideoRecommend"
        title=""
        :footer="null"
        :maskClosable="true"
        @cancel="pauseVideo"
        :width="800"
        :bodyStyle="{display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'black'}"
    >
      <video :src="videoRecommendUrl" ref="videoControl" controls autoplay width="800" height="600" v-if="bPlayVideo"
      />
    </a-modal>

<!--    播放推荐音乐-->
    <div class="recommendAudioControl flexRowAllCenter" :style="{right: getFixRight() +'px'}" v-if="bShowAudioRecommend"
         @click="switchPlayRecommendAudio">
      <img :src="icYinFu" alt="" :class="{rotateImg: bPlayAudio}">
      <audio-player />
    </div>

<!--    书法字体展示-->
    <div class="shufa">
      <img :src="imgPinanxile" alt="">
      <img :src="imgWanshishengyi" alt="">
    </div>
  </div>
</template>

<script>
import {indexBanners, categoryList, latestReommend, insertVisit} from "../../api";
import headerBg from '../../assets/header-bg.jpg'
import icYinFu from '../../assets/ic_yinfu.png'

import {mapMutations} from 'vuex'
import pageFooter from "@/components/pageFooter/pageFooter";
import {meaureMixin} from "@/mixins/meaureMixin";

import {globalEvents} from "@/global/globalEvents";
import eventBus from '../../eventBus/bus'
import AudioPlayer from "@/components/AudioPlayer/AudioPlayer";
import imgWanshishengyi from '../../assets/wssy.png'
import imgPinanxile from '../../assets/paxl.png'
import imgLgqt from '../../assets/lgqt.png'
import imgQydz from '../../assets/qydz.png'
import imgRong from '../../assets/rong.png'
import {formatDateTime} from "@/utils/DateUtil";

export default {
  name: "Index",
  mixins: [meaureMixin],
  data(){
    return{
      icYinFu,
      imgWanshishengyi,
      imgPinanxile,
      imgLgqt,
      imgQydz,
      imgRong,

      bShowPicRecommend: false,
      picRecommendUrl: '',
      clientWidth: 0,
      clientHeight: 0,

      bShowVideoRecommend: false,
      videoRecommendUrl: '',
      bPlayVideo: false,

      bShowAudioRecommend: false,
      bPlayAudio: false,
      recommendAudioUrl: '',

      headerBg,
      toastContent:'',
      bannerList: [],
      categoryList: [],
      curActiveCategory: '博客',
      bMounted: false,

      latestReommendInfo: {},

      mediaTypeEnum: {
        PICTURE: 1,
        AUDIO: 2,
        VIDEO: 3,
        H5: 4
      }
    }
  },

  components: {AudioPlayer, pageFooter},

  methods:{
    ...mapMutations('app', ['mut_showSpinner']),

    onClickPicContainer () {
      this.bShowPicRecommend = false
    },

    //播放视频
    playVideo(videoUrl){
      this.videoRecommendUrl=videoUrl
      this.bPlayVideo=true
    },

    //停止播放视频
    pauseVideo(){
      this.$refs.videoControl.pause()
      this.curPlayVideoUrl=''
      this.bPlayVideo=false
    },

    // 播放推荐歌曲
    playRecommendAudio (url) {
      console.log('playRecommendAudio, url=', url)
      if (url && this.recommendAudioUrl !== url) {
        this.recommendAudioUrl = url
        console.log('设置recommendAudioUrl')
        eventBus.$emit(globalEvents.EVENT_SET_AUDIO_SOURCE, {source: this.recommendAudioUrl})
        eventBus.$emit(globalEvents.EVENT_SET_AUDIO_LOOP, {})
        eventBus.$emit(globalEvents.EVENT_PLAY_AUDIO, {})
        console.log('发送歌曲事件完成')
      } else {
        eventBus.$emit(globalEvents.EVENT_PLAY_AUDIO, {})
      }

      this.bPlayAudio = true
    },

    // 停止播放推荐歌曲
    stopPlayRecommandAudio () {
      eventBus.$emit(globalEvents.EVENT_PAUSE_AUDIO, {})
      this.bPlayAudio = false
    },

    switchPlayRecommendAudio () {
      console.log('switchPlayRecommendAudio, bPlayAudio=', this.bPlayAudio)
      if (!this.recommendAudioUrl) {
        this.playRecommendAudio(this.latestReommendInfo.url)
        return
      }

      if (this.bPlayAudio) {
        this.stopPlayRecommandAudio()
      }  else {
        this.playRecommendAudio()
      }
    },

    // API 获取首页轮播图
    doGetIndexBanners () {
      indexBanners()
        .then(res => {
          console.log('请求返回: ', res)
          this.bannerList = res.data
          this.bannerList = [this.bannerList[0]]
        })
    },

    // API 获取首页栏目列表
    doGetIndexCategoryList () {
      categoryList()
        .then(res => {
          let list = res.data

          this.categoryList = list.filter(_ => !_.category.includes('留言'))
          this.categoryList.push(...list.filter(_ => _.category.includes('留言')))
        })
    },

    // API 获取最新一条媒体推荐
    doGetLatestRecommend () {
      latestReommend().then(res => {
        this.latestReommendInfo = res.data

        switch (this.latestReommendInfo.mediaType) {
          case this.mediaTypeEnum.PICTURE:
              this.picRecommendUrl = this.latestReommendInfo.url
              this.bShowPicRecommend = true
              break

          case this.mediaTypeEnum.VIDEO:
              this.bShowVideoRecommend = true
              this.playVideo(this.latestReommendInfo.url)
              break

          case this.mediaTypeEnum.AUDIO:
              this.bShowAudioRecommend = true
              // this.playRecommendAudio(this.latestReommendInfo.url)
              break
        }
      })
    },

    // API 添加一条访问记录
    doInsertVisitRecord () {
      let Ip=returnCitySN['cip']
      let cityname=returnCitySN['cname']

      let params = {
        ip: Ip,
        location: cityname,
        fromsite: 'pc',
        time: formatDateTime(new Date().getTime())
      }

      insertVisit(params)
        .then(() => {
        })
    },

    showLoading(){
      // this.$showLoading()
      this.mut_showSpinner(true)
    },

    hideLoading(){
      // this.$hideLoading()
      this.mut_showSpinner(false)
    },

    showToast(){
      this.$toast(this.toastContent)
    },

    onClickCategory(item) {
      this.curActiveCategory = item.category
      switch (this.curActiveCategory) {
        case '首页':

        case '博客':
          this.$router.push({
            name: 'Blog'
          })
          break

        case '诗文':
          this.$router.push({
            name: 'Poem'
          })
          break

        case '摄影':
          this.$router.push({
            name: 'Photograph'
          })
          break

        case '留言板':
          this.$router.push({
            name: 'MessageBoard'
          })
          break

        case '录音':
          this.$router.push({
            name: 'SongRecord'
          })
          break

        case '视频':
          this.$router.push({
            name: 'VideoAlbum'
          })
          break

        case '旅行':
          this.$router.push({
            name: 'Travel'
          })
          break
      }
    }
  },

  mounted() {
    this.doGetIndexBanners()
    this.doGetIndexCategoryList()
    this.$nextTick(() => {
      this.bMounted = true
      this.clientWidth = document.documentElement.clientWidth
      this.clientHeight = document.documentElement.clientHeight
      this.calcDyanmicMaxHeight()

      this.doGetLatestRecommend()

     // this.doInsertVisitRecord()
    })
  }
}
</script>

<style scoped lang="scss">
//修改对话框高度
/deep/ .ant-modal-body{
  height: 600px;
}

.ant-carousel >>> .slick-slide {
  height: 500px;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}

</style>

<style scoped lang="scss">
@keyframes widthScale {
  from{
    width: 0;
  }

  to{
    width: 15px;
  }
}

@keyframes rotateAnim {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pageContent{
  .bannerArea{
    width: 100vw;
    height: 500px;

    .bannerItem{
      position: relative;
      img{
        width: 100vw;
        contain: content;
      }
      .bannerTitle{
        position: absolute;
        right: 20px;
        top: 450px;
        background-color: rgba(0,0,0, 0.5);
        color: white;
        padding: 5px 15px;
        font-size: 15px;
      }
    }

    .indicator{
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }
  }

  .mainContents{
    position: relative;
    z-index: 9;
    .mainTitle{
      margin-top: 60px;
      font-size: 30px;
      font-weight: bold;
      text-align: left;
      cursor: pointer;
      img{
        width: 350px;
        user-select: none;
      }
    }

    .categoryList{
      margin-top: 20px;
      .categoryItem{
        margin-right: 30px;
        span:first-child{
          font-size: $font-size-middle;
          color: $textCyan;
          cursor: pointer;
        }

        span.border{
          width: 15px;
          height: 5px;
          border-radius: 2px;
          background-color: transparent;
          margin-top: 10px;
        }
      }

      .activeCategory{
        font-weight: bold;

        span.border{
          background-color: $textCyan;
          animation: widthScale linear 0.2s;
        }
      }
    }

    .dynamicArea{
      margin-top: 50px;
      overflow-y: auto;
      padding-bottom: 100px;

      &::-webkit-scrollbar{
        display: none;
        width: 0;
        height: 0;
      }
    }
  }

  .recommendPicContainer{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    z-index: 99999;
    .recommendImg{

    }
  }

  .recommendAudioControl{
    /*border-top: #ccc 1px solid;
    border-right: #ccc 1px solid;
    border-bottom: #777 1px solid;
    border-left: #777 1px solid;*/
    width: 50px;
    height:50px;
    position: fixed;
    top: 60px;
    border-radius: 50%;
    // background-image: radial-gradient(white, $textCyan);
    z-index: 99;

    img{
      width: 30px;
      height: 30px;
      cursor: pointer;
      animation: none;
      user-select: none;
    }

    .rotateImg{
      animation: rotateAnim 30s infinite linear;
    }
  }

  .shufa{
    position: fixed;
    right: 30px;
    top: 200px;
    transform: translateY(-50%);
    img{
      width: 300px;
      user-select: none;
    }

    img:first-child{
      margin-right: -150px;
    }
  }
}
</style>
