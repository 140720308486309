export const globalEvents={
    EVENT_PLAY_AUDIO:'event_play_audio',
    EVENT_PAUSE_AUDIO:'event_pause_audio',
    EVENT_SET_AUDIO_SOURCE:'event_set_audio_source',
    EVENT_SET_AUDIO_LOOP:'event_set_audio_loop',
    EVENT_CANCEL_AUDIO_LOOP:'event_cancel_audio_loop',
    EVENT_UPLOAD_BLOG_COVER_SUCCESS:'event_upload_blog_cover_success',
    EVENT_BLOG_CONTENT_UPDATE:'event_blog_content_update',
    EVENT_ARTICLE_CONTENT_UPDATE:'event_article_content_update',
    EVENT_POEM_CONTENT_UPDATE:'event_poem_content_update',
    EVENT_UPLOAD_SOUND_FILE_SUCCESS:'event_upload_sound_file_success',
    EVENT_REMOVE_UPLOAD_FILE: 'event_remove_upload_file'
}
