<template>
  <div class="videoDetail" :style="{minHeight: calcDyanmicMaxHeight() + 'px'}">
    <div class="photoList">
      <div class="photoItem" v-for="(item, index) in videoList" :key="index"
           @click="onClickVideoItem(item)" >
        <video :src="item.url" />
        <div class="vTitle">{{item.title}}</div>
        <div class="author" v-if="item.author">{{item.author}}</div>
      </div>

    </div>

    <!--    分页组件-->
    <div class="pagination" :style="{right: getFixRight() + 'px'}">
      <a-pagination v-model="pageParams.page" :total="total" :pageSize="pageParams.limit"/>
    </div>

    <!--    播放推荐视频的对话框-->
    <a-modal
        v-model="bShowVideoModal"
        title=""
        :footer="null"
        :maskClosable="true"
        @cancel="pauseVideo"
        :width="800"
        :bodyStyle="{display:'flex',alignItems:'center',justifyContent:'center',backgroundColor:'black'}"
    >
      <video :src="videoUrl" ref="videoControl" controls autoplay width="800" height="600" v-if="bPlayVideo"
      />
    </a-modal>

  </div>
</template>

<script>
import {pageSelectVideo, updateVideo} from "@/api";
import {meaureMixin} from "@/mixins/meaureMixin";

export default {
  name: "index",
  mixins: [meaureMixin],
  data () {
    return {
      bShowVideoModal: false,
      bPlayVideo: false,
      videoUrl: '',
      curPlayVideoUrl: '',

      albumId: '',
      videoList: [],

      pageParams: {
        limit: 10,
        page: 1
      },

      total: 0
    }
  },

  watch: {
    'pageParams.page': {
      handler: function (newVal) {
        this.doGetVideoList()
      }
    }
  },

  methods: {
    //播放视频
    playVideo(videoUrl){
      this.videoUrl=videoUrl
      this.bPlayVideo=true
      this.bShowVideoModal = true
    },

    //停止播放视频
    pauseVideo(){
      this.$refs.videoControl.pause()
      this.curPlayVideoUrl=''
      this.bPlayVideo=false
    },

    doGetVideoList() {
      const params = {
        ...this.pageParams,
        albumId: this.albumId
      }

      pageSelectVideo(params).then(res=>{
        this.videoList = res.data.records
        this.videoList.forEach(_ => {
          this.$set(_, 'bPlaying', false)
        })
        this.total = res.data.total
      })
    },

    getFixRight () {
      return (document.documentElement.offsetWidth - 1190) / 2
    },

    onClickVideoItem (item) {
      console.log('点击了视频:',item)

      item.viewNum = parseInt(item.viewNum) + 1
      // 更新视频点击量
      const params = {
        ...item
      }
      updateVideo(params)
        .then(res => {
          console.log('更新视频点击量: ', res)
        })

      this.playVideo(item.url)
    }
  },

  mounted() {
    this.albumId = this.$route.params.id
    console.log('视频详情,专辑id=', this.albumId)
    this.doGetVideoList()
  }
}
</script>

<style scoped lang="scss">
.videoDetail {
  .photoList {
    display: flex;
    flex-wrap: wrap;

    .photoItem {
      max-width: 200px;
      max-height: 200px;
      position: relative;
      border-radius: 5px;
      margin-right: 20px;
      margin-bottom: 20px;
      overflow: hidden;
      cursor: pointer;

      video{
        width: 150px;
        height: 150px;
      }

      .vTitle{
        font-size: $font-size-middle;
        color: $textBlack;
        margin-top: 5px;
      }

      .author{
        margin-top: 5px;
        font-size: $font-size-small;
        color: $textGray;
      }

      .albumName {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        left: 5px;
        top: 5px;
      }

      .albumTitle {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        right: 10px;
      }

      .count {
        position: absolute;
        color: white;
        font-size: $font-size-small;
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 5px 10px;
        bottom: 10px;
        left: 10px;
      }

      .square {
        width: 150px;
        height: 150px;
        border: 1px solid white;
        position: absolute;
        left: 25px;
        top: 25px;
      }

      .cover {
        width: 200px;
        height: 200px;
        border-radius: 5px;
        cursor: pointer;
        transition: all .6s;

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }

  .pagination {
    position: fixed;
    bottom: 50px;
  }
}
</style>
